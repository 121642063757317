<template>
  <div>
    <div class="row m-0">
      <div class="col-6 pl-0">
        <select
          v-if="selectedTab === 0"
          class="years-list"
          v-model="selectedYear"
          @change="onChange($event)"
        >
          <option v-for="(option, idx) in yearsList" :key="idx" :value="option.value">
            {{ option.year }}年
          </option>
        </select>
        <select
          v-else
          class="years-list"
          @change="onSelectYearMonth($event)"
          v-model="selectedYearMonth"
        >
          <option v-for="option in yearMonthList" :key="option.month" :value="option.value">
            {{ option.year }}年{{ option.month }}月
          </option>
        </select>
      </div>
      <div class="col-6" style="display: flex; justify-content: right">
        <div style="display: flex; align-items: baseline">
          <button class="filter-btn" @click="onSearchData"></button>
          <i class="search-icon" @click="onSearchData"></i>
          <input
            type="search"
            class="form-control search-input"
            v-model="keySearch"
            @keyup.enter="onSearchData"
          />
          <paginate
            v-if="selectedTab === 0"
            v-model="currentPage"
            :page-count="pageCount"
            :margin-pages="0"
            :page-range="0"
            :first-last-button="false"
            :clickHandler="handlePageSelected"
          ></paginate>
          <nav v-if="selectedTab === 0 && pageCount === 0">
            <ul class="pagination mb-0">
              <li class="page-item disabled">
                <button tabindex="-1" class="page-link">〈</button>
              </li>
              <li class="page-item disabled">
                <button tabindex="0" class="page-link">〉</button>
              </li>
            </ul>
          </nav>
          <span v-if="selectedTab === 0 && !isTotalData" class="recordNum">
            {{ displayDataNumberFrom }} - {{ displayDataNumberTo }} /
            {{ totalEmployeeData }} 件</span
          ><span v-if="selectedTab === 0 && isTotalData" class="recordNum">
            {{ displayDataNumberTo }} / {{ totalEmployeeData }} 件</span
          >
        </div>
      </div>
    </div>
    <div class="row mr-1 tab-selector">
      <div style="width: -webkit-fill-available">
        <b-card no-body>
          <b-tabs
            active-nav-item-class="font-weight-bold active-nav"
            content-class="mt-3"
            v-model="selectedTab"
          >
            <b-tab title="年間累計" active @click.prevent="openAnnualYearTab">
              <div class="totalTable">
                <b-table :items="items" :fields="fields">
                  <template slot="thead-top">
                    <tr role="row">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th colspan="2" class="spanCol">年間累計時間外労働</th>
                      <th colspan="2" class="spanCol">有給取得回数</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </template>
                  <template v-slot:cell(detail)="{ item }">
                    <span><b-btn class="detail-btn" @click="detailInfo(item)"></b-btn></span>
                  </template>
                </b-table>
              </div>
            </b-tab>
            <b-tab title="出勤表" @click.prevent="openAttendanceSheet">
              <attendance-sheet
                ref="attendanceSheet"
                :yearMonth="selectedYearMonth"
                :search="keySearch"
              />
            </b-tab>

            <template #tabs-end v-if="selectedTab === 1">
              <div class="d-flex justify-content-end align-items-center" style="flex: 1">
                <div v-if="!isOpenEdit">
                  <button class="open-edit-btn" @click="openEditDateType">編集</button>
                </div>
                <div v-else>
                  <button class="cancel-edit-btn" @click="cancelEdit">キャンセル</button>
                  <button class="edit-btn" @click="editedDateType">更新</button>
                </div>
              </div>
            </template>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "./../components/Paginate.vue";
import router from "../router";
import axios from "axios";
import Cookies from "js-cookie";
import AttendanceSheet from "./AttendanceManage/AttendanceSheet.vue";

export default {
  components: { Paginate, AttendanceSheet },
  data() {
    return {
      currentPage: 1,
      token: Cookies.get("token").split("|"),
      //number of data on a page
      dataPerPage: 20,
      //number of displayed data
      displayDataNumberFrom: 1,
      displayDataNumberTo: 20,
      isTotalData: false,
      pageCount: 1,
      yearsList: [],

      //define table
      fields: [
        { key: "employee_number", label: "社員番号" },
        { key: "full_name", label: "名前" },
        { key: "recruit_form", label: "雇用形態" },
        { key: "actual_overtimes", label: "実績時間" },
        { key: "standard_overtimes", label: "協定時間" },
        { key: "actual_day_off", label: "実績日数" },
        { key: "obligatory_acquisition_day", label: "取得義務日数" },
        { key: "alert", label: "アラート" },
        { key: "detail", label: "詳細" },
      ],
      items: [],
      keySearch: null,
      selectedYear: String(new Date().getFullYear()),
      totalEmployeeData: 0,
      selectedTab: 0,
      isOpenEdit: false,
      yearMonthList: [],
      selectedYearMonth: null,
    };
  },

  mounted() {
    // get annual data of employee on 年間累計 tab
    this.getEmployeeAnnualData(1); // get data on page 1
    this.getDropdownYearData();
  },

  methods: {
    // get data year display on pulldown 年間累計 tab
    getDropdownYearData() {
      let year = new Date().getFullYear() + 1;
      this.selectedYear = year - 1;

      this.yearsList = [];
      for (let i = 0; i <= 4; i++) {
        let subDropdownValue = {
          value: year - i,
          year: year - i,
        };
        this.yearsList.push(subDropdownValue);
      }
    },

    //get data user per year
    getEmployeeAnnualData(page) {
      let that = this;
      //reset data
      this.items = [];
      axios.defaults.headers.common = {
        Authorization: "Bearer " + that.token[1],
        "X-Api-Key": that.$store.state.headers["x-api-key"],
      };
      axios
        .get(that.$store.state.serverUrl + "attendance/v1", {
          params: {
            tenant_id: Cookies.get("tenantId"),
            year: that.selectedYear,
            key_search: that.keySearch,
            offset: (page - 1) * that.dataPerPage,
            limit: that.dataPerPage,
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data.result.annual_cumulative_totals;
            that.totalEmployeeData = response.data.result.total_count;
            that.pageCount = Math.ceil(that.totalEmployeeData / that.dataPerPage);
            that.displayDataNumberTo =
              that.totalEmployeeData < that.dataPerPage * page
                ? that.totalEmployeeData
                : that.dataPerPage * page;

            that.displayDataNumberFrom = page <= 1 ? 1 : that.dataPerPage * (page - 1) + 1;

            that.isTotalData = that.dataPerPage * (page - 1) + 1 === that.totalEmployeeData;

            data.forEach((element) => {
              const subArray = {
                user_id: element.user_id,
                employee_number: element.employee_number,
                full_name: element.first_name + element.last_name,
                recruit_form: that.getRecruitFormName(element.recruit_form),
                actual_overtimes: element.actual_overtime,
                standard_overtimes: element.standard_overtime,
                actual_day_off: element.actual_day_off,
                obligatory_acquisition_day: element.obligatory_acquisition_day,
                alert: element.actual_overtime < element.standard_overtime * 60 ? "問題なし" : "",
              };
              // push data to items array
              that.items.push(subArray);
            });
          }
        })
        .catch(() => {
          that.isWrongInfo = true;
        });
    },

    //get recruit form name
    getRecruitFormName(recruitId) {
      switch (recruitId) {
        case "0":
          return "正社員";
        case "1":
          return "契約社員";
        case "2":
          return "派遣社員";
        default:
          return "アルバイト、パート";
      }
    },

    // redirect to detail screen
    detailInfo(item) {
      this.$store.dispatch("attendance/getYear", {
        year: this.selectedYear,
      });
      return router.push({
        name: "AttendanceManageDetail",
        query: { id: item.user_id },
      });
    },

    //when select year at dropdown
    onChange(e) {
      this.selectedYear = e.target.value;
      this.getEmployeeAnnualData(this.currentPage);
    },

    //when change page
    handlePageSelected(page) {
      this.currentPage = page;
      this.getEmployeeAnnualData(this.currentPage);
    },

    // on get data follow specifically condition
    onSearchData() {
      if (this.selectedTab === 0) {
        this.getEmployeeAnnualData(1); // when search data, return to page 1
        this.currentPage = 1;
      } else if (this.selectedTab === 1) {
        this.$refs.attendanceSheet.getDateTypeData(this.selectedYearMonth, this.keySearch);
      }
    },

    // when click 年間累計 tab from 出勤表 tab
    openAnnualYearTab() {
      this.keySearch = null;
      this.getDropdownYearData();
      this.getEmployeeAnnualData(1); // get data on page 1
      // reset data on 出勤表/attendance sheet tab
      this.cancelEdit();
    },

    // open 出勤表/attendance sheet tab
    openAttendanceSheet() {
      this.keySearch = null;
      this.getDropdownYearMonthData();
      this.$refs.attendanceSheet.getDateTypeData(this.selectedYearMonth, this.keySearch);
    },

    // get dropdown year-month data
    getDropdownYearMonthData() {
      this.yearMonthList = [];
      let year = String(new Date().getFullYear());
      let month = String(new Date().getMonth() + 2);
      for (let i = 0; i <= 4; i++) {
        let dropdownMonth = month - i;
        let value = year + String("00" + dropdownMonth).slice(-2);
        let subDropdownValue = {
          value: value,
          year: year,
          month: dropdownMonth,
        };
        if (i === 1 /* current month */) {
          this.selectedYearMonth = value;
        }
        this.yearMonthList.push(subDropdownValue);
      }
    },

    // when change data on year-month dropdown
    onSelectYearMonth(e) {
      this.$refs.attendanceSheet.getDateTypeData(e.target.value, this.keySearch);
    },

    // on click button 編集
    openEditDateType() {
      this.isOpenEdit = true;
      this.$refs.attendanceSheet.enableEdit();
    },

    // on click button キャンセル
    cancelEdit() {
      this.isOpenEdit = false;
      this.$refs.attendanceSheet.cancelEdit();
    },

    // on click button 更新
    editedDateType() {
      this.isOpenEdit = false;
      this.$refs.attendanceSheet.updateValue();
    },
  },
};
</script>

<style scoped>
.row {
  font-family: "Roboto";
}
.years-list {
  width: 102px;
  height: 37px;
  margin-left: 23px;
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  -webkit-appearance: none;
}
.years-list:focus {
  outline: none;
}

.filter-btn {
  background: url("../assets/filter-icon.png");
  width: 24px;
  height: 24px;
  border: none;
  margin-right: 25.83px;
}
.search-icon {
  background: url("../assets/search-icon.png");
  width: 22px;
  height: 21px;
  margin-right: -21px;
  z-index: 1;
}
.form-outline {
  border-bottom: 0.5px solid #b6b1b1;
}
.search-input {
  width: 206px;
  border: none;
  border-bottom: 0.5px solid #b6b1b1;
  border-radius: 0px;
  padding-left: 40px;
}
.search-input:focus {
  outline: none;
  box-shadow: none;
}
input.search-input[type="search"]::-webkit-search-decoration,
input.search-input[type="search"]::-webkit-search-cancel-button,
input.search-input[type="search"]::-webkit-search-results-button,
input.search-input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.recordNum {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-right: 20px;
}
.tabs >>> .mt-3 {
  margin-top: 11px !important;
}
.tabs >>> .nav-link {
  border-right: 0.5px solid #b6b1b1;
  border-top: 0.5px solid #b6b1b1;
  width: 205px;
  color: #000000;
  text-align: center;
  border-radius: 0;
}
.tabs >>> .active-nav {
  border-bottom: 7px solid #8bdff9 !important;
}
.tab-selector {
  margin-left: 13px;
  margin-top: 18px;
}
.totalTable {
  margin: 0px 25px 0px 10px;
  border: 0.5px solid #b6b1b1;
  overflow-y: scroll;
  height: 78vh;
}
.card {
  border: none;
  border-left: 0.5px solid #b6b1b1;
  border-bottom: 0.5px solid #b6b1b1;
}
.b-table {
  margin-bottom: 0;
}
.b-table >>> thead {
  background: rgba(0, 0, 0, 0.03);
  font-weight: normal;
}
.spanCol,
.b-table >>> th[aria-colindex="5"],
.b-table >>> th[aria-colindex="6"],
.b-table >>> th[aria-colindex="7"],
.b-table >>> th[aria-colindex="8"],
.b-table >>> td[aria-colindex="5"],
.b-table >>> td[aria-colindex="6"],
.b-table >>> td[aria-colindex="7"],
.b-table >>> td[aria-colindex="8"] {
  text-align: center;
}
.detail-btn {
  background: url("../assets/next-icon.png");
  width: 24px;
  height: 24px;
  border: none;
}
.table >>> th {
  font-weight: normal;
}

.edit-btn,
.open-edit-btn {
  margin: 0 10px;
  width: 80px;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
  color: #ffffff;
}

.cancel-edit-btn {
  width: 100px;
  color: #948c8c;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.page-item.disabled .page-link {
  color: #b6b1b1;
  border: none;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
