<template>
  <div style="height: 77vh; overflow-y: auto">
    <b-table
      :items="items"
      :fields="visibleFields"
      class="border"
      style="color: #000000 !important"
    >
      <template v-for="(field, idx) in visibleFields" v-slot:[`cell(${field.key})`]="{ item }">
        <div v-if="field.key !== 'employee_number' && field.key !== 'full_name'" :key="idx">
          <select
            class="dropdown-data"
            :id="'dropdown-' + item.key + '-' + field.key"
            v-model="item[field.key]"
            :disabled="!editable"
            :style="{ background: setDropdownValueColor(item[field.key]) }"
            @change="setChangedValueColor($event)"
          >
            <option v-for="(option, idx) in dateTypeOption" :key="idx" :value="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
        <div v-else :key="idx">
          <span>{{ item[field.key] }}</span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from "axios";
import jsCookie from "js-cookie";
export default {
  props: {
    yearMonth: String,
    search: null,
  },

  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },

  data() {
    return {
      isAllChecked: false,
      fields: [],
      items: [],
      editable: false,
      dateTypeOption: [],
      changeValueSelect: [],
      selectedType: [],
      token: jsCookie.get("token"),
    };
  },

  mounted() {
    this.getDateTypeList();
  },

  methods: {
    // get list date type code display on dropdown
    async getDateTypeList() {
      this.dateTypeOption = [];
      axios.defaults.headers.common = {
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      // get data from api
      const { data } = await axios.get(this.$store.state.serverUrl + "attendance/date-type/v1", {
        params: {
          tenant_id: jsCookie.get("tenantId"),
        },
      });

      // format received data
      data.result?.forEach((item) => {
        let code = {
          key: item.date_type_code,
          value: item.date_type_name,
        };

        this.dateTypeOption.push(code);
      });
    },

    // get display header depend on number day of month
    getDisplayFields(yearMonth) {
      let year = yearMonth.substr(0, 4);
      let month = yearMonth.substr(4, 2);

      // get number day of month
      let daysInMonth = new Date(year, month, 0).getDate();

      this.fields = [];
      // fixed header
      this.fields = [
        {
          key: "employee_number",
          label: "社員番号",
          visible: true,
          thClass: "employee-number-col",
        },
        {
          key: "full_name",
          label: "名前",
          visible: true,
          thClass: "full-name-col",
        },
      ];

      // header format equivalent the number of day in month
      for (let i = 1; i <= daysInMonth; i++) {
        let date = new Date(year, month - 1, i);
        let dayOfWeek;
        // get day of week
        switch (date.getDay()) {
          case 1:
            dayOfWeek = "月";
            break;
          case 2:
            dayOfWeek = "火";
            break;
          case 3:
            dayOfWeek = "水";
            break;
          case 4:
            dayOfWeek = "木";
            break;
          case 5:
            dayOfWeek = "金";
            break;
          case 6:
            dayOfWeek = "土";
            break;
          default:
            dayOfWeek = "日";
        }

        this.fields.push({
          key: "day_" + i,
          label: i + "日 (" + dayOfWeek + ")",
          visible: true,
        });
      }
    },

    // get date type code data for each user
    async getDateTypeData(yearMonth, keySearch) {
      // reset data
      this.items = [];
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token,
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      // get data from API
      const { data } = await axios.get(this.$store.state.serverUrl + "attendance/sheet/v1", {
        params: {
          tenant_id: jsCookie.get("tenantId"),
          year_month: yearMonth,
          key_search: keySearch,
        },
      });

      // format data
      data.result?.forEach((el) => {
        const dateTypeList = el.attendances;
        let subItem = {
          key: el.user_id,
          employee_number: el.employee_number,
          full_name: el.first_name + " " + el.last_name,
        };
        dateTypeList?.forEach((type) => {
          const date = Number(type.date.split("-")[2]);
          subItem[`day_${date}`] = type.date_type_code;
        });
        this.items.push(subItem);
      });
    },

    // when click button 編集 enable to editing data
    enableEdit() {
      this.changeValueSelect = [];
      this.selectedType = [];
      this.editable = true;
    },

    // when click button キャンセル to cancel edit data
    cancelEdit() {
      // reset data
      this.getDateTypeData(this.yearMonth, this.search);

      // change color of edited text to black
      this.changeValueSelect.forEach((el) => {
        el.color = "black";
      });
      // reset array contain the edited list value
      this.changeValueSelect = [];
      this.editable = false;
    },

    // set dropdown background color
    setDropdownValueColor(value) {
      switch (value) {
        case "H01":
          return "#FFCDCD";
        case "H02":
          return "#CDDEFF";
        case "V01":
        case "V02":
          return "#D9D9D9";
        default:
          return "";
      }
    },

    // set edited value text color to red
    setChangedValueColor(e) {
      e.target.style.color = "red";
      this.changeValueSelect.push(e.target.style);
      let isExisted = this.selectedType.some((x) => x.id === e.target.id);
      if (!isExisted) {
        this.selectedType.push({
          id: e.target.id,
          value: e.target.value,
        });
      } else {
        let newVal = [...this.selectedType];
        let idx = newVal.findIndex((x) => x.id === e.target.id);
        newVal[idx].value = e.target.value;
      }
    },

    // when click button 更新 to update value
    updateValue() {
      let updateValues = [];
      let tempUpdateVal = [];

      //format changed list value
      this.selectedType.forEach((item) => {
        //get user id and date from dropdown id (format dropdown-userId-day_x)
        let subItem = item.id.split("-");
        const user_id = subItem[1];
        const day = subItem[2].substr(4);

        // get year & month from selected yearMonth
        let year = this.yearMonth.substr(0, 4);
        let month = this.yearMonth.substr(4, 2);

        let subUpdateValue = {
          user_id: Number(user_id),
          date: `${year}-${month}-${day}`, // format data to YYYY-MM-D
          date_type_code: item.value,
        };

        tempUpdateVal.push(subUpdateValue);
      });

      // put changed list value to update format
      var tempResult = tempUpdateVal.reduce(function (temp, el) {
        let index = el["user_id"];
        (temp[index] ? temp[index] : (temp[index] = null || [])).push({
          date: el.date,
          date_type_code: el.date_type_code,
        });
        return temp;
      }, {});
      for (const [key, value] of Object.entries(tempResult)) {
        updateValues.push({ user_id: Number(key), attendances: value });
      }

      // update value
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token,
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      axios.put(this.$store.state.serverUrl + "attendance/sheet/v1", {
        users: updateValues,
      });

      // reset dropdown value text color to black
      this.changeValueSelect.forEach((el) => {
        el.color = "black";
      });
      this.changeValueSelect = [];
      this.editable = false;
    },
  },

  watch: {
    yearMonth: {
      handler(newVal) {
        this.getDisplayFields(newVal);
      },
    },
  },
};
</script>
<style scoped>
.b-table >>> thead {
  background: rgba(0, 0, 0, 0.03);
  font-size: 15px !important;
}
.b-table >>> tbody {
  font-size: 14px !important;
}
.b-table >>> th {
  font-weight: normal !important;
  vertical-align: middle !important;
  border-top: none !important;
  border-bottom: none !important;
}
.b-table >>> .employee-number-col div {
  width: 80px;
}
.b-table >>> .full-name-col div {
  width: 80px;
}

.b-table >>> td[aria-colindex="1"],
.b-table >>> td[aria-colindex="2"] {
  background: #d7f4fa;
}

.b-table >>> th[aria-colindex="2"],
.b-table >>> td[aria-colindex="2"] {
  border-right: 0.5px solid #b6b1b1;
}

.dropdown-data {
  text-align: center;
  width: 60px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  -webkit-appearance: none;
  white-space: pre-wrap;
}
.dropdown-data:focus {
  outline: none;
}
.dropdown-data:disabled {
  color: #000000;
  opacity: 1;
  border: none;
}
option {
  background: #ffffff;
  color: #000000;
}
</style>
